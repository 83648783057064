<!-- Similar to the Nuxt loading indicator 'pulse' -->
<template>
		<div class="loading-container" :key="'loading'">
			<div class="spinner">
				<div class="double-bounce1"></div>
				<div class="double-bounce2"></div>
			</div>
			<slot />
		</div>
</template>

<style scoped>
.loading-container {
	background: #f5f5f5;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
}
.spinner {
	width: 40px;
	height: 40px;
	background-color: #98d8b1;
	margin-left: 20px;

	border-radius: 100%;
	-webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
	animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
	0% { -webkit-transform: scale(0) }
	100% {
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}

@keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	} 100% {
			-webkit-transform: scale(1.0);
			transform: scale(1.0);
			opacity: 0;
		}
}
</style>
